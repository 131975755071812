import React from 'react'
import { generateElementID, MAIN_PAGE_ID_PREFIX } from 'utils/generateElementID'
import { Wrapper, WrapperVideo } from './style'

const YoutubeVideo = () => (
  <Wrapper>
    <h1>Our Solutions</h1>
    <WrapperVideo>
      <iframe
        id={ generateElementID(MAIN_PAGE_ID_PREFIX, 'youtubevideo') }
        src="https://www.youtube.com/embed/6M5XL_N2fII"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </WrapperVideo>
  </Wrapper>
)

export default YoutubeVideo
