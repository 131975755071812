import styled, { css } from 'styled-components'

import { paddingPage } from '../styles'
import { 
  darkBlueGrey, 
  regentGray, 
  emerald, 
  mykonosBlue 
} from 'components/baseColor'
import { IS_FRAME_MOBILE, IS_FRAME_TABLET } from 'utils'

export const PricingContainer = styled.div`
  ${paddingPage}
  padding-top: 2rem;

  ${IS_FRAME_TABLET} {
    padding: 0rem 3rem 0rem;
  }

  ${IS_FRAME_MOBILE} {
    padding: 0rem 0rem 0rem 0rem;
  }
`

export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${IS_FRAME_MOBILE} {
    flex-direction: column-reverse;
    text-align: center;
  }

  > p {
    margin-left: 1rem;
    margin-right: 1rem;
    font-size: 1rem;

    ${IS_FRAME_MOBILE} {
      font-size: 0.75rem;
      margin-left: 0rem;
      margin-right: 0rem;
    }
  }

  p {
    font-weight: bold;
  }

  > p:first-child {
    color: ${darkBlueGrey};
    opacity: 1;

    ${({ checked }) => checked &&
      css`
        color: ${regentGray};
        opacity: 0.8;
      `}
  }

  > p:last-child {
    color: ${darkBlueGrey};
    opacity: 1;

    ${({ checked }) => !checked &&
      css`
        color: ${regentGray};

        opacity: 0.8;
      `}
  }
`

export const BaseListCard = styled.div`
  display: grid;
  grid-gap: 2em;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(min-content, max-content);
  align-items: center;
  justify-content: space-around;
  margin-top: 4rem;
  grid-row-gap: 5rem;

  ${IS_FRAME_TABLET} {
    margin: 2rem 2rem 1rem;
    padding-top: 2rem;
    grid-row-gap: 5rem;
    grid-template-columns: repeat(2, 1fr);
  }

  ${IS_FRAME_MOBILE} {
    margin: 0 2rem 1rem;
    display: flex;
    padding-top: 2rem;
    flex-direction: column;
    grid-row-gap: 2rem;
  }
`

export const CardContainer = styled.div`
  background: transparent;
  position: relative;
  width: 100%;
  min-width: 100px;
  min-height: 400px;
  border-radius: 8px;
  border: solid 1px ${darkBlueGrey};
  text-align: center;
  height: 100%;
  display: grid;
  grid-template-rows: 0.35fr 1fr max-content;
  margin-bottom: 5rem;

  ${IS_FRAME_MOBILE} {
    min-width: 262px;
    min-height: 400px;
    height: -moz-available;
    height: -webkit-fill-available;
    grid-template-rows: ${({ isCustom }) => isCustom ? '0.1fr 0.35fr 1fr max-content' : '0.35fr 1fr max-content'};
    margin-bottom: 2rem;
  }
`

CardContainer.HeadCustomText = styled.div`
  background-color: ${mykonosBlue};
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  overflow: hidden;
  padding: 1em 0;
  color: #fff;
  position: absolute;
  width: 100.7%;
  top: -2.7em;
  left: -1px;

  ${IS_FRAME_MOBILE} {
    display: flex;
    position: static;
    align-items: center;
    justify-content: center;
    height: 1.2rem;
  }
`

export const HeaderCard = styled.div`
  padding: 1em;
  border-bottom: solid 0.5px ${darkBlueGrey};
`

HeaderCard.Title = styled.p`
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
  color: ${darkBlueGrey};
`

HeaderCard.Desc = styled.p`
  margin: 0;
  font-size: 0.9rem;
  color: ${darkBlueGrey};

  ${IS_FRAME_MOBILE} {
    font-size: 0.875rem;
  }
`

HeaderCard.Pricing = styled.h1`
  text-transform: uppercase;
  color: ${darkBlueGrey};
  margin: 0;
  line-height: 1;
  letter-spacing: 2px;
  font-size: calc(100% * 1.5);
  color: ${darkBlueGrey};

  ${IS_FRAME_MOBILE} {
    margin-bottom: 0.5rem;
  }
`

HeaderCard.SavePricingText = styled.p`
  color: ${emerald};
  margin-top: 0.5rem;
  font-size: 1rem;
  margin: 0;
`

export const DescCard = styled.div`
  color: ${darkBlueGrey};
  background-color: rgb(255,255,255);

  p {
    font-family: font-family: "Open Sans", system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji" !important;
    font-size: 0.9rem;
    padding: 0 1em;

    span {
      font-family: "Open Sans", system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial,
      sans-serif, "Apple Color Emoji", "Segoe UI Emoji" !important;
      color: ${darkBlueGrey} !important;
      font-size: 0.9rem !important;
    }
  }
`

export const FooterCard = styled.div`
  padding: 2em 3em;

  ${IS_FRAME_MOBILE} {
    width: -webkit-fill-available;
    width: -moz-available;
  }
`

export const ReactToggleCSS = styled.div`
  .react-toggle {
    touch-action: pan-x;

    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
  }

  .react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .react-toggle--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
  }

  .react-toggle-track {
    width: 3.5em;
    height: 24px;
    padding: 0;
    border-radius: 30px;
    background-color: #e9eef3;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #dce6f3;
  }

  .react-toggle--checked .react-toggle-track {
    background-color: ${darkBlueGrey};
  }

  .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #2b2f65;
  }

  .react-toggle-track-check {
    position: absolute;
    width: 14px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 8px;
    opacity: 0;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }

  .react-toggle--checked .react-toggle-track-check {
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }

  .react-toggle-track-x {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 10px;
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }

  .react-toggle--checked .react-toggle-track-x {
    opacity: 0;
  }

  .react-toggle-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 22px;
    height: 22px;
    border: 1px solid #e9eef3;
    border-radius: 50%;
    background-color: #fafafa;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }

  .react-toggle--checked .react-toggle-thumb {
    left: 18px;
    border-color: ${darkBlueGrey};
  }

  .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    -webkit-box-shadow: 0px 0px 5px 5px #0099e0;
    -moz-box-shadow: 0px 0px 5px 5px #0099e0;
    box-shadow: 0px 0px 5px 5px #0099e0;
  }
`

export const WrapperLoading = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 2em;
`
