import React, { 
  useEffect, 
  useCallback, 
  useState
} from 'react'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { navigate } from 'gatsby'
import {
  Layout,
  Button,
  SEO
} from 'components'
import { 
  HeaderPage, 
  ContentHeader, 
  BackgroundImage,
  FormContainer,
  WrapperPricing
} from './styles'
import qs from 'query-string'
import API, { BlogURL, TransactionURL } from 'commons/API'
import Fuse from 'fuse.js'
import loadable from '@loadable/component'
import YoutubeVideo from './YoutubeVideo'
import bgImageHand from './media/bg-image.png'
import { 
  generateElementID, 
  CONTENT_TITLE_ID_PREFIX, 
  CONTENT_DESCRIPTION_ID_PREFIX, 
  BUTTON_FREE_TRIAL_ID_PREFIX 
} from 'utils/generateElementID'
import RegisterForm from 'domains/Register/form'
import PricingSection from 'domains/Pricing/PricingSection'
import HeaderPricingSection from 'domains/Pricing/HeaderSection'
import { CART_TYPES } from 'store/types'

const SearchResult = loadable(() => import('domains/SearchResult'))
const SolutionSection = loadable(() => import('./SolutionSection'))
const AdvantageSection = loadable(() => import('./AdvantageSection'))
const HowItWorks = loadable(() => import('./HowItWorks'))
const ClientSection = loadable(() => import('./ClientSection'))
const FeedbackSection = loadable(() => import('./FeedbackSection'))
const BigRegisterSection = loadable(() => import('components/BigRegisterSection'))

function IndexPage({ location }) {
  const intl = useIntl()
  const [searchResults, setSearchResults] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [defaultChoosen, setDefaultChoosen] = useState('')
  const dispatch = useDispatch()

  const handleSearch = useCallback((keyword) => {
    API.get(BlogURL.GetAllBlog)
      .then((response) => {
        const fuse = new Fuse(response.data.contents, {
          keys: [
            'title',
            'slug',
            'description',
            'content_body'
          ],
          minMatchCharLength: keyword.length
        })

        const results = fuse.search(keyword)

        setSearchResults(results)
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        alert(error.response.message)
      })
  }, [])

  const queryParams = location?.search
  const search = qs.parse(queryParams)?.search

  useEffect(() => {
    if(search) {
      handleSearch(search)
    }
  }, [handleSearch, search])

  useEffect(() => {
    const getDefaultChoosen = async () => {
      try {
        const { data: ipUser } = await API('https://api.ipify.org/?format=json')
        const { data: dataLocation } = await API(`https://ipapi.co/${ipUser.ip}/json/`)
        setDefaultChoosen(dataLocation.country)
      } catch (err) {
        console.log('--- error getting current location', err.message)
      }
    }
    getDefaultChoosen()
  }, [])

  // detect country code by ip
  useEffect(() => {
    const getCountryCurrency = async () => {
      try {
        const { data: ipUser } = await API('https://api.ipify.org/?format=json')
        const { data: dataLocation } = await API(`https://ipapi.co/${ipUser.ip}/json/`)

        dispatch({ type: CART_TYPES.SET_CURRENCY, currency: dataLocation.currency })
        sessionStorage.setItem('geoLocation', JSON.stringify(dataLocation))
      } catch (err) {
        console.log('--- error getting current location', err.message)
      }
    }

    const geoLocation = sessionStorage.getItem('geoLocation')
    if(!geoLocation) getCountryCurrency()
    else {
      const currency = JSON.parse(geoLocation).currency
      dispatch({ type: CART_TYPES.SET_CURRENCY, currency: currency })
    }

    (async function getCurrencies() {
      try {
        const fetchAPI = await API(TransactionURL.GetCurrencies)
        const { currencies } = await fetchAPI.data
        const constructCurrencies = currencies.map((currency) => {
          const countryCode = currency.name.toUpperCase()

          return {
            ...currency,
            countryCode,
            currency : currency.name,
            name     : `${currency.country} (${countryCode})`
          }
        })

        dispatch({ type: CART_TYPES.SET_CURRENCIES, currencies: constructCurrencies })
      } catch (err) {
        console.log('--- error get list currencies', err.message)
      }
    })()
  }, [dispatch])

  return (
    <>
      <SEO
        title="Beyond Innovation, Driving Impact"
        description="Qlue provides comprehensive smart city solutions focusing on Artificial Intelligence (AI), Internet of Things (IoT), and mobile workforce technology"
      />
      <Layout location={ location }>
        {
          search
            ? (
              <SearchResult 
                isLoading={ isLoading }
                location={ location }
                searchResults={ searchResults }
              />
            )
            : (
              <>
                <BackgroundImage src={ bgImageHand } alt={ 'background' }/>
                <HeaderPage>
                  <ContentHeader>
                    <h1 id={ generateElementID(CONTENT_TITLE_ID_PREFIX, 'aipowered') }>{ intl.formatMessage({ id: 'e26795e65' }) }</h1>
                    <p id={ generateElementID(CONTENT_DESCRIPTION_ID_PREFIX, 'aipowered') }>{ intl.formatMessage({ id: '6ceeee3a2' }) }</p>
                    <Button id={ generateElementID(BUTTON_FREE_TRIAL_ID_PREFIX, 'aipowered') } onClick={ () => navigate('#our-solutions') } type="warning">
                      { intl.formatMessage({ id: 'fac763932' }) }
                    </Button>
                  </ContentHeader>
                  <FormContainer>
                    <RegisterForm defaultChoosen={ defaultChoosen } location={ location } />
                  </FormContainer>
                </HeaderPage>
                <SolutionSection />
                <AdvantageSection />
                <HowItWorks />
                { /* New Section, Our Solutions */ }
                <div id="our-solutions">
                  <YoutubeVideo />
                </div>
                { /* New Section, Pricing Plan */ }
                <WrapperPricing>
                  <HeaderPricingSection />
                  <PricingSection location={ location } />
                </WrapperPricing>
                <ClientSection />
                <FeedbackSection />
                <BigRegisterSection 
                  caption='cd82fb5cc'
                  btnText='4c0aed557'
                  redirect='/register'
                />
              </>
            )
        }
      </Layout>
    </>
  )
}

export default IndexPage
