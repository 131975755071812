import styled from 'styled-components'
import { IS_FRAME_MOBILE, IS_FRAME_TABLET } from 'utils'
import { darkBlueGrey } from 'components/baseColor'

export const Wrapper = styled.div`
  padding: 8em 5em 0;
  background: #fff;

  ${IS_FRAME_TABLET} {
    padding: 0 2em;
  }

  ${IS_FRAME_MOBILE} {
    padding: 0 2em;
  }

  > h1 {
    text-transform: capitalize;
    font-size: 4.167em;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 2px;
    text-align: center;
    margin: 0;
    margin-bottom: 0.5em;

    ${IS_FRAME_MOBILE} {
      font-size: 2em;
      padding: 0 2em;
    }

    > a {
      color: ${darkBlueGrey};
      text-decoration: none;
    }
  }
`

export const WrapperVideo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  iframe {
    width: 80%;
    aspect-ratio: 16 / 9;
  }
`