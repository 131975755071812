/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useEffect } from 'react'
import { navigate } from 'gatsby'
import Toggle from 'react-toggle'
import qs from 'query-string'
import { useSelector, useDispatch } from 'react-redux'
import { CART_TYPES } from 'store/types'
import { FormattedMessage } from 'utils/intl'

import {
  PricingContainer,
  ToggleContainer,
  CardContainer,
  HeaderCard,
  ReactToggleCSS,
  WrapperLoading,
  DescCard,
  FooterCard,
  BaseListCard
} from './styles'
import Button from 'components/Button'
import Shimmer from 'components/Shimmer'
import API, { PricingURL } from 'commons/API'
import { formatMoney } from 'utils'

function PriceCard({
  type,
  currencies = [],
  detailCurrency = {},
  isAnnualy,
  features,
  data = { account_packages_prices: [] },
  onClick
}) {
  const isCustom = type === 'custom'
  const currency = detailCurrency?.currency ?? ' '
  const getPriceMonthly = useMemo(() => {
    let newPrice = 0

    if (data.account_packages_prices.length > 0) {
      data.account_packages_prices.forEach((price) => {
        const regex = new RegExp(currency, 'gi')

        if (price.currency.name.search(regex) > -1) newPrice += price.price_monthly
      })
    }

    return newPrice
  }, [currency, data.account_packages_prices])

  const price = useMemo(() => {
    const dataPrice = { annually: data.price_annualy, monthly: data.price_monthly }

    if (data.account_packages_prices.length > 0) {
      data.account_packages_prices.forEach((price) => {
        const regex = new RegExp(currency, 'gi')

        if (price.currency.name.search(regex) > -1) {
          dataPrice.annually = price.price_annually
          dataPrice.monthly = price.price_monthly
        }
      })
    }

    return isAnnualy ? dataPrice.annually : dataPrice.monthly
  }, [currency, data.account_packages_prices, data.price_annualy, data.price_monthly, isAnnualy])

  const calcSavedPrice = getPriceMonthly * 12 - price

  // google tag manager
  const gtmId = data.name ? data.name.split(' ').join('-').toLowerCase() : 'custom'

  return (
    <CardContainer isCustom={ isCustom }>
      { isCustom && <CardContainer.HeadCustomText>Try Custom</CardContainer.HeadCustomText> }

      <HeaderCard>
        <HeaderCard.Title>{ !isCustom ? data.name : 'custom' }</HeaderCard.Title>
        { !isCustom && <HeaderCard.Pricing>{ formatMoney(price, { currency }) }</HeaderCard.Pricing> }
        <HeaderCard.Desc isCustom={ isCustom }>
          { !isCustom ? (isAnnualy ? '/year' : '/month') : '' }
          { isCustom && 'Customize plan to suit what your business needs' }
        </HeaderCard.Desc>

        { isAnnualy && calcSavedPrice > 0 && (
          <HeaderCard.SavePricingText>
            Save { formatMoney(calcSavedPrice, { currency }) } a year
          </HeaderCard.SavePricingText>
        ) }
      </HeaderCard>

      { !isCustom ? (
        <DescCard dangerouslySetInnerHTML={{ __html: data.description }} />
      ) : (
        <DescCard>
          <p>Build your own plan based on the improvements you need for your business.</p>
        </DescCard>
      ) }

      <FooterCard>
        <Button 
          // id for GTM purposes
          id={ gtmId }
          width="100%" 
          onClick={ onClick } 
          type="warning"
        >
          <FormattedMessage id="e93ba683f" />
        </Button>
      </FooterCard>
    </CardContainer>
  )
}

const PricingSection = ({ location }) => {
  const dispatch = useDispatch()
  const { cart } = useSelector((state) => state)
  const { packages } = cart
  const [isAnnualy, setIsAnnualy] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [isLoading, setLoading] = useState(true)
  const mappingPackages = useMemo(
    () => [...packages].map((pckg) => ({
      ...pckg,
      qryString: qs.stringify({
        package      : pckg.name,
        billedPeriod : isAnnualy ? 'annualy' : 'monthly'
      })
    })),
    [isAnnualy, packages]
  )

  const queryParams = location?.search

  const special_package_id_for_testing = qs.parse(queryParams)?.special_package_id_for_testing

  const handleClickPackage = (data) => () => {
    const productsToObject = data.products.reduce((acc, product) => {
      acc[product.id] = product
      return acc
    }, {})

    dispatch({ type: CART_TYPES.SET_CUSTOM_PACKAGE, isCustom: false })
    dispatch({
      type            : CART_TYPES.SET_SELECTED_PACKAGE,
      selectedPackage : { ...data, products: productsToObject }
    })

    navigate('/cart')
  }

  const handleCustomClick = () => {
    dispatch({ type: CART_TYPES.SET_CUSTOM_PACKAGE, isCustom: true })
    dispatch({
      type            : CART_TYPES.SET_SELECTED_PACKAGE,
      selectedPackage : { products: {}, id: 'Custom Plan', name: 'Custom Plan' }
    })

    navigate('/cart')
  }

  useEffect(() => {
    (async function getData() {
      setLoading(true)
      setErrorMsg('')

      const payload = {}
    
      // NOTE: hardcode for testing only
      if (special_package_id_for_testing) {
        payload.id = '103dcf61-3e0d-4723-a84a-8cc195c69e85'
      }
      // change to this payload after testing
      else payload.is_custom = [false]

      try {
        const fetchAPI = await API.post(PricingURL.GetListPrice, {
          filter: payload
        })
        const packages = fetchAPI.data.account_packages

        dispatch({ type: CART_TYPES.TYPE_SET_PACKAGES, packages })
      } catch (err) {
        setErrorMsg(err.message)
      }

      setLoading(false)
    })()
  }, [dispatch, special_package_id_for_testing])

  useEffect(() => {
    let data
    if (isAnnualy) data = { name: 'annually' }
    else data = { name: 'monthly' }

    dispatch({ type: CART_TYPES.CHANGE_PAYMENT_PERIOD, paymentPeriod: data })
  }, [dispatch, isAnnualy])

  return (
    <PricingContainer>
      <ToggleContainer checked={ isAnnualy }>
        <p><FormattedMessage id="8dd193a88" /></p>
        <ReactToggleCSS>
          <Toggle
            defaultChecked={ isAnnualy }
            icons={ false }
            onChange={ () => setIsAnnualy((state) => !state) }
          />
        </ReactToggleCSS>
        <p><FormattedMessage id="bbcb347d7" /></p>
      </ToggleContainer>
      { isLoading ? (
        <LoadingSkeleton />
      ) : errorMsg ? (
        <p>{ errorMsg }</p>
      ) : (
        <BaseListCard>
          { mappingPackages.map((dataPackage) => (
            <PriceCard
              key={ dataPackage.id }
              onClick={ handleClickPackage(dataPackage) }
              data={ dataPackage }
              currencies={ cart.currencies }
              currency={ cart.currency }
              detailCurrency={ cart.detailCurrency }
              isAnnualy={ isAnnualy }
            />
          )) }
          <PriceCard type="custom" onClick={ handleCustomClick } />
        </BaseListCard>
      ) }
    </PricingContainer>
  )
}

function LoadingSkeleton(params) {
  const DUMMY_DATA = Array(4).fill()

  return (
    <WrapperLoading>
      { DUMMY_DATA.map((_, i) => (
        <Shimmer height="calc(100vh - 33em)" key={ i.toString() } />
      )) }
    </WrapperLoading>
  )
}

export default PricingSection
