import styled from "styled-components";

import { paddingPage } from "../styles";
import { darkBlueGrey } from "components/baseColor";
import { IS_FRAME_MOBILE } from "utils";

export const HeaderContainer = styled.div`
  ${paddingPage}
  padding-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  ${IS_FRAME_MOBILE} {
    padding: 2rem 2rem 0;
  }

  > h1 {
    text-transform: uppercase;
    color: ${darkBlueGrey};
    margin: 0;
    line-height: 1.28;
    letter-spacing: 2px;

    ${IS_FRAME_MOBILE} {
      font-size: 2em;
    }
  }

  > p {
    color: ${darkBlueGrey};
    @media only screen and (max-width: 768px) {
      font-size: 1.15em;
    }
  }
`;
