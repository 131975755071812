// dependency libraries
import React, { useState, useEffect, useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { navigate } from 'gatsby'
import Recaptcha from 'react-google-invisible-recaptcha'
import { useSelector } from 'react-redux'
import qs from 'query-string'

// root modules
import useDispatch from 'commons/hooks/useDispatch'
import {
  InputText,
  InputPhone,
  Button,
  CheckBox, 
  InputCountry
}from 'components'
import { REG_EMAIL, REG_NUMBER } from 'components/dictionary'
import API from 'commons/API'
import { isBrowser } from 'utils'

// parent modules
import useCountries from './models/useCountries'
import { postRegister, fetchDefault } from './stores/action'

// sibling modules
import {
  BoxRegister,
  TitleRegister,
  DeskripsiRegister,
  AccountRendering,
  CheckUpdate,
  TextUpdate,
  FormRegister
} from './style'

const dashboardURL = process.env.GATSBY_DASHBOARD_URL

const RegisterForm = ({ location, defaultChoosen }) => {
  const HOST = isBrowser ? window.location.host : ''
  const PROTOCOL = isBrowser ? window.location.protocol : ''
  const ACTIVATION_LINK = `${PROTOCOL}//${HOST}/set-password/`

  const queryString = qs.parse(location.search)
  const countries = useCountries()
  const postDispatchRegister = useDispatch(postRegister)
  const fetchDefaultRegister = useDispatch(fetchDefault)
  const refButton = useRef()
  const refSubmitBtn = useRef()
  const [isLoading, setLoading] = useState(false)
  const resRegister = useSelector((state) => state.register)
  const [state, setState] = useState({
    name: {
      value : '',
      error : ''
    },
    email: {
      value : '',
      error : ''
    },
    company: {
      value : '',
      error : ''
    },
    phone: {
      value   : '',
      country : 'id',
      id      : '62',
      error   : ''
    },
    country: {
      value: {
        countryName: 'Indonesia'
      },
      error: ''
    }
  })
  const [agree, setAgree] = useState(false)
  const intl = useIntl()
  const [, setDefaultCountry] = useState('')
  
  useEffect(() => {
    const getVisitorCountry = async () => {
      try {
        const { data: ipUser } = await API('https://api.ipify.org/?format=json')
        const { data: dataLocation } = await API(`https://ipapi.co/${ipUser.ip}/json/`)
        const phoneCodeNumber = dataLocation.country_calling_code.split('+').pop()
        const country = dataLocation.country.toLowerCase()
        const countryName = dataLocation.country_name
        state.country.value.countryName = countryName
        state.phone.id = phoneCodeNumber
        state.phone.country = country
        setState({ ...state })
        sessionStorage.setItem('geoLocation', JSON.stringify(dataLocation))
      } catch (err) {
        console.log('--- error getting current location', err.message)
      }
    }
    
    const geoLocation = sessionStorage.getItem('geoLocation')
    if(!geoLocation) getVisitorCountry()
    else {
      const phoneCodeNumber = JSON.parse(geoLocation).country_calling_code.split('+').pop()
      const country = JSON.parse(geoLocation).country.toLowerCase()
      const countryName = JSON.parse(geoLocation).country_name
      state.country.value.countryName = countryName
      state.phone.id = phoneCodeNumber
      state.phone.country = country
      setState({ ...state })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (resRegister.register.error) {
      let fieldError = ''
      if (resRegister.register.error.search(/email/gi) > -1) fieldError = 'email'
      else if (resRegister.register.error.search(/name/gi) > -1) fieldError = 'name'
      else if (resRegister.register.error.search(/company/gi) > -1) fieldError = 'company'
      else if (resRegister.register.error.search(/phone/gi) > -1) fieldError = 'phone'

      setState({
        ...state,
        [fieldError]: { ...state[fieldError], error: resRegister.register.error }
      })
      fetchDefaultRegister()
      setLoading(false)
    } else if (resRegister.register.isLoaded) {
      fetchDefaultRegister()
      setTimeout(() => {
        navigate('/registersuccess', { state: { permission: true } })
      }, 500)
    }
  }, [resRegister, fetchDefaultRegister, state])

  const onResolved = () => {
    console.log('Response recaptcha v3' + refButton.current.getResponse())
  }

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    setState({ ...state, [name]: { ...state[name], value: value } })
  }

  const handleChangeCountry = (country) => {
    setDefaultCountry(country.alpha2Code.toLowerCase())
    setState((prevState) => ({
      ...prevState,
      country: { ...prevState.country, value: country, error: '' }
    }))
  }

  const handleBlur = (name, value) => {
    let error = ''
    if (value === '') {
      error = `Please enter Your ${name}`
    } else if (name === 'name') {
      if (value.length > 50 || value.length < 4) {
        error = 'Character minimal 4 and maximal 50'
      }
    } else if (name === 'company') {
      if (value.length > 50 || value.length < 3) {
        error = 'Character minimal 3 and maximal 50'
      }
    } else if (name === 'email') {
      if (!REG_EMAIL.test(value)) {
        error = 'your email is not valid'
      } else if (value.length > 50) {
        error = 'Character maximal 50'
      }
    }
    setState({ ...state, [name]: { error: error, value: value } })
  }

  const handleClickAgree = (e) => {
    setAgree(e.target.checked)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
   
    const { name, email, company, country } = state
    const isFormValid =
      name.error === '' &&
      email.error === '' &&
      company.error === '' &&
      country.error === '' &&
      phone.error === '' &&
      name.value &&
      email.value &&
      company.value &&
      country.value &&
      phone.value

    const execute = refButton.current.execute()
    execute
      .then(() => {
        if (isFormValid) {
          const valueForm = {
            name              : name.value,
            email             : email.value,
            company           : company.value,
            country           : country.value.countryName,
            country_dial_code : phone.id,
            activation_link   : ACTIVATION_LINK,
            next_link         : queryString.next_link,
            phone_number      : phone.value,
            country_iso2      : phone.country
          }

          postDispatchRegister(valueForm)
        } else {
          throw new Error('Registration failed')
        }
      })
      .catch(() => {
        let dataError = {}
        for (const datas in state) {
          if (state[datas].value === '' && datas.search(/(country)/gi) === -1) {
            dataError = {
              ...dataError,
              [datas]: { ...state[datas], error: `Please enter Your ${datas}` }
            }
          }
        }

        if (!country.value) {
          dataError.country = {
            ...state.country,
            error: 'Please pick your country'
          }
        }

        setState({ ...state, ...dataError })
        setLoading(false)
      })
  }

  const handleChangePhone = (isValid, phone, country) => {
    if (REG_NUMBER.test(phone) || phone === '') {
      setState({
        ...state,
        phone: {
          id      : country.dialCode,
          value   : phone.replace(/^0+/, ''),
          country : country.iso2,
          error   : ''
        }
      })
    }
  }

  const { name, email, phone, company, country } = state

  return (
    <FormRegister autoComplete='off' onSubmit={ handleSubmit }>
      <BoxRegister>
        <TitleRegister>
          <span>
            <FormattedMessage id='249027353' />
          </span>
        </TitleRegister>
        <DeskripsiRegister>
          <span>
            <FormattedMessage id='11b384dd0' />
          </span>
        </DeskripsiRegister>
        <InputText
          name='name'
          title={ intl.formatMessage({ id: '478df0a7f' }) }
          value={ name.value }
          onChange={ handleChange }
          error={ name.error }
          onBlur={ handleBlur }
          placeholder={ intl.formatMessage({ id: 'eff379382' }) }
        />
        <InputText
          name='email'
          type='email'
          title={ intl.formatMessage({ id: 'a8ebc8742' }) }
          value={ email.value }
          onChange={ handleChange }
          error={ email.error }
          onBlur={ handleBlur }
          placeholder={ intl.formatMessage({ id: 'd100971b3' }) }
        />
        <InputText
          name='company'
          title={ intl.formatMessage({ id: '473c283ec' }) }
          value={ company.value }
          onChange={ handleChange }
          error={ company.error }
          onBlur={ handleBlur }
          placeholder={ intl.formatMessage({ id: '2bc30239a' }) }
        />

        <InputCountry
          withSearch
          keySearch='countryName'
          svgPosition={{ top: '5px' }}
          borderColor='#bcbfc8'
          py='4px'
          options={ countries.list }
          errorText={ country.error }
          selectedItem={ country.value }
          selectedKey='countryName'
          placeholder={ intl.formatMessage({ id: '50f9931ba' }) }
          onChange={ handleChangeCountry }
        />
        
        <InputPhone
          placeholder={ intl.formatMessage({ id: '23867fe93' }) }
          name='phone'
          value={ phone.value }
          onChange={ handleChangePhone }
          onPhoneNumberBlur={ () => {
            if(phone.value.length < 4 || phone.value.length > 14) {
              setState((prevState) => ({
                ...prevState,
                phone: {
                  value   : phone.value,
                  country : phone.country,
                  id      : phone.id,
                  error   : 'Digit minimal 4 and maximal 14'
                }
              }))
            }
          } }
          error={ phone.error }
          title={ intl.formatMessage({ id: '7c93abef9' }) }
          defaultCountry={ defaultChoosen }
        />
        <CheckUpdate>
          <CheckBox 
            handleClick={ handleClickAgree } 
          />
          <TextUpdate>
            <span>{ intl.formatMessage({ id: 'I-have' }) }{ ' ' }
              <a
                className="terms-and-conditions"
                href="/privacy-policy"
                target="_blank"
              >
                { intl.formatMessage({ id: 'Terms' }) }
              </a>
            </span>
          </TextUpdate>
        </CheckUpdate>
        <Button ref={ refSubmitBtn } isLoading={ isLoading } disabled={ !agree } buttonType='warning' type='submit'>
          { intl.formatMessage({ id: 'e89c1c11d' }) }
        </Button>
        <AccountRendering>
          <span>
            <a href={ dashboardURL } target='_blank' rel='noreferrer'>
              <span>{ intl.formatMessage({ id: 'c6af8ff20' }) }</span>
            </a>
          </span>
        </AccountRendering>
        <Recaptcha
          sitekey='6Ld-yKcZAAAAABPLzurtwbwZzoVUCriWDkbLRPtQ'
          onResolved={ onResolved }
          ref={ refButton }
        />
      </BoxRegister>
    </FormRegister>
  )
}

export default RegisterForm
