import styled, { css } from 'styled-components'
import { darkBlueGrey } from 'components/baseColor'
import { IS_FRAME_MOBILE, IS_FRAME_TABLET } from 'utils'

export const paddingPage = css`
  padding: 0 5em;

  ${IS_FRAME_MOBILE} {
    padding: 2em 0;
  }
`

export const HeaderPage = styled.div`
  ${paddingPage}
  position: relative;
  padding-top: 79px;
  padding-bottom: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* CSS specific to iOS devices */
  @supports (-webkit-touch-callout: none) {
    background-attachment: scroll;
  }
  
  ${IS_FRAME_TABLET} {
    flex-direction: column;
    padding: 2em 2em 1em;
    justify-content: start;
    min-height: 60vh;
    background-position: -130px -10px;
    margin-bottom: 0;
  }

  ${IS_FRAME_MOBILE} {
    flex-direction: column;
    padding: 2em;
    justify-content: start;
    background-position: -130px -10px;
    margin-bottom: 0;
  }

  iframe {
    width: 50% !important;
    height: 30em;

    ${IS_FRAME_TABLET} {
      width: 100% !important;
      height: 25rem;
    }

    ${IS_FRAME_MOBILE} {
      width: 100% !important;
      height: 22em;
    }
  }
`

export const BackgroundImage = styled.img`
  position: fixed;
  z-index: -1;
  width: 100%;
  top: 0;
  max-width: 1600px;

  ${IS_FRAME_MOBILE} {
    height: 70%;
    width: auto;
    max-width: 100vw;
    z-index: -10;
    position: absolute;
  }
`

export const ContentHeader = styled.div`
  color: ${darkBlueGrey};
  width: 50%;

  ${IS_FRAME_TABLET} {
    width: 100%;

    button {
      padding: 0.5em 1em;
      width: 127px;
      margin-bottom: 1.5em;
    }
  }

  ${IS_FRAME_MOBILE} {
    width: 100%;

    h1 {
      font-size: 1.8em;
    }

    p {
      font-size: 1.15em;
      margin: 1em 0;
    }

    button {
      padding: 0.5em 1em;
      width: 127px;
      margin-bottom: 1.5em;
    }
  }

  h1 {
    font-size: 3.4em;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    margin-bottom: 0;
    width: 95%;
  }

  p {
    font-size: 1.3em;
    font-weight: 300;
    letter-spacing: 0.1px;
    width: 90%;
  }

  button {
    font-size: 1.3em;
  }
`

export const WrapperRegisterNow = styled.div`
  padding: 1em 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8em;
  background: #fff;

  ${IS_FRAME_MOBILE} {
    padding: 3em 0;
  }

  button {
    ${IS_FRAME_MOBILE} {
      padding: 10px 65px;
      font-size: 14px;
    }
  }
`

export const FormContainer = styled.div`
  width: 35%;
  margin-left: auto;

  ${IS_FRAME_TABLET} {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-left: 0;
  }

  ${IS_FRAME_MOBILE} {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`

export const WrapperPricing = styled.div`
  background-color: #fff;
`
