import styled, { css } from 'styled-components'
import { IS_FRAME_MOBILE } from 'utils'

export const paddingPage = css`
  padding: 0 8rem;
`

export const WrapperClient = styled.div`
  margin: 3rem 0 4rem;

  ${IS_FRAME_MOBILE} {
    margin-bottom: 0;
    margin-top: 3rem;
  }
`
