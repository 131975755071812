import React from 'react'
import { HeaderContainer } from './styles'
import { FormattedMessage } from 'utils/intl'

const HeaderSection = () => (
  <HeaderContainer>
    <h1><FormattedMessage id="a13e1dd8c" /></h1>
    <p><FormattedMessage id="969e90258" /></p>
  </HeaderContainer>
)

export default HeaderSection
