import { REQUEST_REGISTER, SUCCESS_REGISTER, FAILURE_REGISTER } from "./constant";
import API, { Register } from "commons/API";

export const postRegister = (data) => {
  return {
    types: {
      request: REQUEST_REGISTER,
      success: SUCCESS_REGISTER,
      failure: FAILURE_REGISTER,
    },
    call: (dispatchSuccess) =>
      API.post(Register.RegisterUrl, data).then((res) => dispatchSuccess(res.data)),
  };
};

export const fetchDefault = () => {
  return {
    types: {
      default: "CHANGE_DEFAULT",
    },
  };
};
